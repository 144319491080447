import { useEffect, useState } from "react";
import tw from "twin.macro";
import { useLocation, useNavigate } from "react-router-dom";
import Noimg from "../images/no_img.jpg";
import { joinScheme, dayClose } from "../api/NewSchemeApi";
import {
  getBranchId,
  getCustomerId,
  getCustomerName,
} from "../utility/HeaderConfig";
import moment from "moment";
import { motion } from "framer-motion";
import { branchdetails } from "api/BranchDetailsApi";
import { getAllDomainEmployeeDetails } from "api/EmployeeApi";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

export default ({ configImages }) => {
  const [Employee, setEmployee] = useState("");
  const [DayClose, setDayClose] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [BranchMobile, setBranchMobile] = useState("");
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getBanner();
    dayCloseDay();
    employees();
    getbranchdetails();
    //eslint-disable-next-line
  }, []);

  const getBanner = () => {
    configImages &&  configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "joinScheme") {
        setBanner(data.value);
      }
    });
  };

  const location = useLocation();
  const schemeDetails = location.state !== undefined ? location.state : "";
  let statusName =schemeDetails[1]?.type?.toLowerCase() === "digigold" ? "Active" : "Waiting_For_Approval";
  const [Installment, setInstallment] = useState(
    schemeDetails.chitAmount ? schemeDetails.chitAmount : 0
  );
  const [Modal, setModal] = useState([]);
  const history = useNavigate();

  const dayCloseDay = () => {
    dayClose({
      module: "savings_scheme",
      branchId: getBranchId(),
    }).then((data) => {
      setDayClose(moment(data.response[0].activeDate).format("YYYY-MM-DD"));
    });
  };

  const employees = () => {
    getAllDomainEmployeeDetails({ name: "ONLINEPAYMENT" }).then((data) => {
      setEmployee(data.response[0].id);
    });
  };

  const getbranchdetails = () => {
    branchdetails(getBranchId()).then((data) => {
      setBranchMobile(data.response.mobile);
    });
  };

  const close = () => {
    history("/MyScheme");
  };

  let endDate = moment(DayClose)
    .add(parseFloat(schemeDetails.noOfMonths - 1), "M")
    .format("DD-MM-YYYY");

  const joinData = () => {
    let endDate = moment(DayClose)
      .add(parseFloat(schemeDetails.noOfMonths - 1), "M")
      .format("YYYY-MM-DD");

    const filters = {
      metaChit: {
        id: schemeDetails.id,
      },
      branchId: getBranchId(),
      collectionType: "online",
      employeeId: Employee,
      customerId: {
        id: getCustomerId(),
      },
      enrollmentDate: DayClose,
      scheduleCompletionDate: endDate,
      customerCredibility: "Neutral",
      status: statusName,
      chitAmount: Installment,
      enrollmentMode: "WEB",
      chitEnrollmentType: "NEW",
    };

    const responseData = joinScheme(JSON.stringify(filters));
    responseData.then(
      (data) => {
        setModal(data.response.chitId);
        setshowModal(true);
        toast.success("Your saving scheme has been saved successfully...!", {
          duration: 3000,
          position: "top-right",
        });
      },
      resp => {
        if (resp && resp.response.data.errorCode === '417') {
          toast.error(`Scheme Enrollment Details ${resp.response.data.displayMessage !== '' ? resp.response.data.displayMessage : resp.response.data.errorMsg}`, {
            duration: 3000,
            position: "top-right",
          });
        } else {
            toast.error(`Failed, Scheme Enrollment Details not Created`, {
            duration: 3000,
            position: "top-right",
          });        
        }
      }
    )   
  };

  return (
    <>
      <Toaster position="top-right" />
      {banner && banner.length > 0 ? (
        <img src={banner} alt="join scheme" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}

      <section tw=" py-5 md:py-20 relative " className="custom_background">
        <div className="container container-influid-md">
          <div className="row align-items-center justify-content-around">
            <div className=" col-md-12 col-lg-6 col-xl-4 mb-3 mb-0">
              <div tw="rounded-lg">
                <img
                  src={
                    schemeDetails.chitImage !== null &&
                    schemeDetails.chitImage !== "" &&
                    schemeDetails.chitImage !== "-" &&
                    schemeDetails.chitImage !== "Image_Not_Available"
                      ? schemeDetails.chitImage
                      : Noimg
                  }
                  alt="schemeImage"
                  className="w-100"
                  tw="hover:shadow-2xl rounded-t-lg"
                />
                <div
                  className="bg-white m-auto"
                  tw="w-full border border-custom-100 shadow-2xl rounded-b-lg"
                >
                  <h3 tw="text-xl m-0 uppercase  text-center py-4">
                    {schemeDetails.chitTypeName}
                  </h3>
                  <div
                    className="ptSans d-flex py-2 px-4 justify-content-between"
                    tw="text-base border-dashed border-custom-100 border-b-2 border-t-2"
                  >
                    <p tw="m-0">Duration</p>
                    <p tw="m-0">{schemeDetails.noOfMonths}</p>
                  </div>
                  <div
                    className="ptSans d-flex py-2 px-4 justify-content-between"
                    tw="text-base border-dashed border-custom-100 border-b-2"
                  >
                    <p tw="m-0">Total Payable</p>
                    <p tw="m-0">{schemeDetails.maturityAmount}</p>
                  </div>
                  <div
                    className="ptSans d-flex py-2 px-4 justify-content-between"
                    tw="text-base border-dashed border-custom-100 border-b-2"
                  >
                    <p tw="m-0">Installment</p>
                    <p tw="m-0">{schemeDetails.chitAmount}</p>
                  </div>
                  <div
                    className="ptSans d-flex py-2 px-4 justify-content-between"
                    tw="text-base"
                  >
                    <p tw="m-0">Maturity Date</p>
                    <p tw="m-0">{endDate}</p>
                  </div>
                </div>

                {/* <div className="p-3" tw="bg-custom-200">
                  <h6 tw="text-xl text-custom-100 mb-2 text-center uppercase">
                    {schemeDetails.chitTypeName}
                  </h6>

                  <table tw="leading-9" className="col-md-6 w-100">
                    <tr>
                      <td tw="px-3">Duration</td>:
                      <td tw="px-3">{schemeDetails.noOfMonths}</td>
                      <td tw="px-3">Total payable</td>:
                      <td tw="px-3">{schemeDetails.maturityAmount}</td>
                    </tr>
                    <tr>
                      <td tw="px-3">Installment</td>:
                      <td tw="px-3">{schemeDetails.chitAmount}</td>
                      <td tw="px-3">Maturity Dt</td>:
                      <td tw="px-3">{endDate}</td>
                    </tr>
                  </table>
                </div> */}
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <form
                className="w-100 bg-white rounded "
                tw="shadow-2xl p-5 py-10 sm:p-10 "
              >
                <b tw="uppercase tracking-wide text-4xl block text-center ">
                  Join Scheme
                </b>
                <div tw="bg-custom-100 w-6 h-px text-center m-auto my-3"></div>
                <div className="mb-4 ptSans">
                  <label tw="text-sm mb-2">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    tw="cursor-not-allowed py-3"
                    value={getCustomerName()}
                    disabled
                  />
                </div>
                <div className="mb-4 ptSans">
                  <label tw="text-sm mb-2">Installment</label>
                  <input
                    type="text"
                    className="form-control"
                    tw="py-3"
                    value={
                      Installment > 0 && Installment !== ""
                        ? Installment
                        : 0
                    }
                    onChange={(e) => {
                      setInstallment(e.target.value);
                    }}
                    disabled={
                      schemeDetails.chitPaymentType &&
                      (schemeDetails.chitPaymentType === "FLEXIBLE" ||
                        schemeDetails.chitPaymentType === "FLEXIBLE_ENROLLMENT")
                        ? false
                        : true
                    }
                  />
                </div>
                <div className="mb-4 ptSans">
                  <label tw="text-sm mb-2">Maturity Date</label>
                  <input
                    type="text"
                    tw="py-3 cursor-not-allowed"
                    className="form-control"
                    value={DayClose}
                    disabled
                  />
                </div>
                <div className=" col-12 my-lg-0 pt-2">
                  <div id="holder">
                    <div className="button" tw="h-12" onClick={joinData}>
                      <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                        Submit
                      </p>
                      <div className="btnTwo">
                        <p className="btnText2">
                          <i className="bi bi-plus-circle-dotted"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showModal ? (
          <>
            <motion.div
              initial={{ x: "700px", opacity: 0, scale: 0.5 }}
              animate={{ x: 0, opacity: 1, scale: 1 }}
              tw="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div tw="relative w-auto my-20 mx-auto  w-full sm:w-1/4">
                {/*content*/}
                <div tw="relative border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div tw="flex items-start justify-center items-center p-2 border-b border-solid border-custom-100 rounded-t">
                    <h6
                      tw="text-lg m-0 uppercase"
                      className="text-success text-center"
                    >
                      Success
                    </h6>
                  </div>
                  {/*body*/}
                  <div tw="relative p-6 pb-3 flex-auto text-center">
                    <p tw="mb-2">Your Chit ID : {Modal}</p>
                    <p tw="mb-1">Please Contact Customer Support</p>
                    <strong>{BranchMobile}</strong>
                    <p tw="mb-2 mt-2">To activate your scheme</p>
                    <p tw="mb-2 mt-2">Working Days : Wednesday - Tuesday</p>
                    <p tw="mb-2 mt-2">Working Hours : 9.00 AM - 8.00 PM</p>
                  </div>
                  <div tw="text-center">
                    <button
                      type="button"
                      className="btn btn-dark w-25 border-0 "
                      tw="bg-custom-100 mb-4"
                      onClick={close}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
            <div tw="opacity-25 fixed inset-0 z-40 bg-black "></div>
          </>
        ) : null}
      </section>
    </>
  );
};
