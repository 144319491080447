import { useEffect, useState } from "react";
import tw from "twin.macro";
import Skeleton from "react-loading-skeleton";
import SEO from "components/headers/Seo";

const Heading = tw.h1`text-center  block text-black font-bold text-3xl m-0 py-2 uppercase border-b-2 border-custom-100`;

export default ({ configImages , branch }) => {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    getBanner();
    //eslint-disable-next-line
  }, []);

  const getBanner = () => {
    if (configImages) {
      configImages &&
        configImages.E_COMMERCE_BANNERS.forEach((data) => {
          if (data.key === "termsConditions") {
            setBanner(data.value);
          }
        });
    }
  };

  let  Site_Url = "https://vclive.prod-rasiinfotech";

  const list = [
    {
      title: `Greetings from ${branch.storeName} !!!`,
      description: `We value what you value. And indeed for us Jewels value more than its physical value. ${branch.storeName} always anchors this principle in its value system and takes utmost care in honoring that. Please read carefully about our company’s terms and get to know what and how we serve our customers.`,
      show: `show`,
    },
    {
      title: `Owned and operated by`,
      description: `The website is owned and operated by ${branch.storeName} having its registered office at ${Object.keys(branch).length > 0 && branch.branchAddress.address1 + branch.branchAddress.address2}.`,
      show: "show",
    },
    {
      title: `ANNOTATION`,
      description: `${branch.storeName} Limited shall henceforth be addressed as
     ’${branch.storeName} or ’The
     management’ or ’The Jeweller’ or ’The company’ or ’The
     corporate’ or ’Us’ or ’We’ or ’${Site_Url}.in’ or ’${branch.storeName}.`,
      show: "",
    },
    {
      title: `USER AGREEMENT`,
      description: `${branch.storeName} policies are rules and guidelines that create and promote safe and fair trading environment for all its customers. User Agreement is the predominant policy. All users or members are responsible for going over, understanding ${branch.storeName} policies and all applicable laws and regulations of this contract and abiding by the same.`,
      show: "",
    },
    {
      title: `Amendments`,
      description:
        "The user agreement shall be inferred in accordance with the applicable and prevailing laws of India. The website policies follow the Government of India’s amendment to Information Technology Amendment Act, 2008 or ITAA 2008 which focuses on Information Security, Cyber Terrorism and Data Protection. Sensitive Personal Information and Reasonable Security Practices mentioned in section 43A of ITAA, 2008 will be followed.",
      description2:
        `Those who choose to use this website from abroad or outside of India are responsible for the compliances with the local laws. Delivery of products shall be provided only within geographical boundary of India. Any interest towards shipping overseas is purely the customers interest and ${branch.storeName} or its associates shall not be liable for any such transactions.`,
      show: "",
    },
    {
      title: `Eligibility Criteria`,
      description: `Any user who’s deemed to be ’incompetent to contract’ by Indian Contract Act, 1872 is not eligible to use this website. Anyone who is below 18 years of age shall not be allowed to become a member or to use or purchase or enter into contract through the website of ${branch.storeName}.`,
      show: "",
    },
    {
      title: `Registration Obligation`,
      description: `All the information provided by the users have to be true, accurate, to the detail, current and complete as required by the registration process of ${Site_Url}. If the user provides any information that isn’t in accordance with the above stated criteria and or if the management of ${branch.storeName} has a reasonable cause to suspect or consider that the information provided by you to be untrue or deviates from the criteria or not in concurrence of opinion with the user agreement, ${branch.storeName} has every right to cancel or suspend or terminate the membership and restrict the access with ${Site_Url} indefinitely.`,
      show: "",
    },
    {
      title: `Registration`,
      description: `Registration with ${Site_Url} is made simple and easy with just a few steps and it is free of cost.`,
      show: "",
    },
    {
      title: `Contact & Personal Information`,
      description: `User has to enter their personal information such as Name, Address and Phone number. Personal information includes Date of Birth, which ${Site_Url} uses to verify the user’s age. After registration, user may change their personally identifiable information such as the login password in their profile by logging into their account and it is always advisable not to share your login details with anyone and to update the password at periodic intervals to keep it protected.`,
      show: "",
    },
    {
      title: `Email Verification`,
      description: `User has to enter their email address which serves as the medium of communication from ${Site_Url}. User shall check the email which provides the instruction for user to register and helps in registration process.`,
      description2: `Having a working email address is important as ${branch.storeName} uses it to notify about the confirmation of purchase, shipping, payment procedures and details about the payment.`,
      show: "",
    },
    {
      title: `Acceptance of User Agreement & Privacy Policy`,
      description: `Read the user agreement and privacy policy, if you accept the terms and conditions of user agreement along with the privacy policy, check the box indicating acceptance of the contract. You must accept the contract to continue registration failing which you should not use the website.`,
      tabs: "collapse9",
      show: "",
    },
    {
      title: `Create User Info`,
      description: `User ID is required for users to login and purchase. You can create a user ID and set a password. The choice of User ID is based on the availability of the user name. If you ever forgot your password, make use of the ’Forgot Password’ link in the login page. You will be prompted to enter your registered email address. Once you enter the mail address & click ’Submit’ button you will receive a mail containing the link to reset your password. You are advised to set(or reset) a strong password which is a combination of letters, numbers & special characters.`,
      show: "",
    },
    {
      title: `Confirm Registration`,
      description: `Check the email for confirmation of registration. Use this registered email for communication with the company. You shall be responsible for all activities that occur under your account.`,
      show: "",
    },
    {
      title: `Registration for Guest User`,
      description: `It is always advised to sign up to avail the following benefits: Simpler process with enhanced safety, profile management, tracking of order summary and faster processing of your enquiry.`,
      show: "",
    },
    {
      title: `Tax and Value Added Charges`,
      description: `As per the current regulations of Government of India, all Jewels be it Gold, Silver, Diamond , Platinum or any other precious metal with or without precious or semi precious stones that are billed will fall within the scope of GST, which shall subsume the tax structure that had been in force till June 2017.`,
      description2: `The cost of the jewel includes the physical content of Jewels, designing cost and making charges along with the wastages. ${branch.storeName} has every right in determining the cost of the product. These costs shall be referred to as ’Value Added Charges’.`,
      tabs: "collapse13",
      show: "",
    },
    {
      title: `Termination of Agreement`,
      description: `The management of ${branch.storeName} is vested with the right to terminate the membership of the users and their agreement when it is found that any member violates the terms and conditions or privacy policy as such. ${Site_Url} is not duty bound to provide prior notice to any member about terminating their membership and restricting them from accessing the website. It is required that the member has to erase or destroy the downloaded or copied data obtained from ${Site_Url} under the user agreement in the event of termination of membership. Termination of membership shall not in any way alter your obligation of any outstanding payment towards the purchase of the merchandise.`,
      description2: `The user can terminate his/her membership by writing to the company that he/she no longer wishes to avail the services of ${Site_Url} and discontinue further use of this website. Your request for termination shall be processed in 7 to 10 business days. However you still ought to complete any arrears with payments.’.`,
      show: "",
    },
    {
      title: `PRODUCTS ONLINE`,
      description: `${branch.storeName} takes care in providing elaborate information about the products that are displayed in the website. However, users may expect more information regarding the product. In such a case, members can contact our customer care or support via email or toll free number displayed prominently in the header or footer sections of the web page. We solicit your valuable feedback and suggestions.`,
      show: "",
    },
    {
      title: `Product Availability`,
      description: `The website ${Site_Url} may indicate availability of a product for sale by displaying its image. However, an unforeseen situation of non-availability of the product may arise. In such a case ${branch.storeName} will endeavor to arrange it either from a third party or design it in any of its manufacturing units. Prompt communication will be initiated by ${branch.storeName} if so or even otherwise via email or phone.`,
      description2: `Please take cognizance of the fact that for jewels or precious stones, regardless of the displayed information sales can occur only based on the current availability of the product. In such a case, actual prices may vary from the prices indicated in the site.`,
      show: "",
    },
    {
      title: `Photography`,
      description: `While ${branch.storeName} exercises great care and caution while photographing the jewels and uploading them to the site(${Site_Url}) to provide the users with a real-time viewing experience, possibility of any minor deviation with respect to shade, design, size due to factors such as lighting, reflection of stones, camera & sensor quality cannot be ruled out . We hope user is aware of these practical issues while placing the order with us and shall not hold ${branch.storeName} as accountable at a later stage.`,
      tabs: "collapse15",
      show: "",
    },
    {
      title: `Product Customization`,
      description: `If a user requires a jewel exclusively designed to satiate his/her soul by performing modifications in existing design by adding or removing or changing the precious or semi precious stones mounted on the ornament as such, they shall contact the sales or support team and get to know on the possibility of customization. Customization of a product is the prerogative of ${branch.storeName}. It may take up the order for customization or indicate inability, at its own determination. Buyer has to bear the customization cost fixed by the company.`,
      show: "",
    },
    {
      title: `Product Price`,
      description: `The product price is inclusive of the price of the material(s), designing or craftsmanship cost, making charges along with the wastage fee and Tax component. ${branch.storeName} has every right in determining the price of the product. These costs shall be referred as ’Value Added Charges’.

    All prices quoted and displayed in the website are in INR(Indian National Rupees). Though the price displayed in the website is the final price of the product covering price of the metal, stones, value added charges, handling charges (if applicable) and ’tax’, please be aware that the prices of jewellery fluctuate in accordance with international metal market trends and hence the final price may be subject to minor variations within the short span of making payment. In such a case, you may have to pay a bit higher or lower than the price indicated in the site. Handling charges refer to the charges we may levy in case of unanticipated practical difficulties in delivery or due to higher rates of payment to payment gateway. Check the ’Tax & Value Added Charges’ section of the terms and conditions for more clarity.
    
    The price of gold displayed as gold rate is the price announced by the ’Madras Jewellers & Diamond Merchants association’ at Chennai for 22 carat gold. Kindly confirm the price before opting for payment procedure. However, ${branch.storeName}.com puts continuous efforts in updating the price listed in the website. ${branch.storeName} reserves the right to alter the prices with or without notice.
    
    The price of diamond and precious stones is subject to availability of the stones and will be based on market trends.
    
    The weight of the product displayed in the website as ’product weight’ is an approximate indication of the product’s weight and actual weight may vary slightly.`,
      description2: `Price of the product is based on the indicated weight and payment shall also be made for the same. If situation arises such that the physical weight of the purchased product is lower than the indicated weight for which the payment is made, the difference of amount corresponding to the difference in weight of the gold will be sent back as a cheque and will be shipped to the customer along with the product. The price of the gold, in this case, is calculated based on the market rate of the gold that prevails on the date and time of the purchase. This act of gesture showed by ${branch.storeName} is purely in its interest towards the satisfaction of customer and it shall not be considered as a mandatory practice.

    The price displayed and product detail may be subject to typographical errors though every reasonable effort is made to make the data free of such errors. In such situation, users should clarify the price with the customer support team of ${branch.storeName}. If the product price quoted, listed or displayed in the website is higher than the actual price, ${branch.storeName} will contact the member and will inform the right price for the selected Jewel. Similarly, if otherwise, ${branch.storeName} shall contact the user to inform the right price and shall make invoice on the informed price or shall cancel the order based on customer’s interest.
    
    Due to the fluctuation in market price, the price of the metals (gold, silver or platinum) and Gems/stones, may vary from the time of order (or added to cart) and the time of purchase or the time at which the payment is made. The price at the time of purchase will surpass all other prices.`,
      show: "",
    },
    {
      title: `Confirmation of Order`,
      description: `ail will be sent to the member after the purchase is finalized with ${Site_Url}. User shall use this email as reference for their purchase.`,
      show: "",
    },
    {
      title: `Payments`,
      description: `Payment shall be made through credit card or debit card or through net banking or transferred through NEFT via banks. Once an order is placed user will be directed through payment gateway for online payment and transactions to purchase the merchandise and user shall choose their payment option.

    It is assumed that every member who is a (bank’s) cardholder is aware of the terms and conditions of online banking and usage of bank cards. Else, check with your bank’s policy. User should understand, agree and confirm that the information they provide on the debit or credit card while updating their user account profile should not impersonate other person. The same principle holds good for internet banking; the user shall not use other person’s internet banking account to transact with this website.
    
    The information shall not be utilized or shared with third party unless required for legal verification by agencies such as judiciary or police enquiry.`,
      description2: `${branch.storeName} shall not be responsible for intentional deception, fiddle or fraud of debit or credit card or internet banking by any third party while using the website. Check the privacy policy.

    ${branch.storeName} reserves every right to undo any purchase process even after the payment is done either as full or part payment. The reason for rejection shall be cited through the contact information provided. Kindly refer the ’Cancellation terms’ part of the terms and conditions. In case of refund, the payment shall be credited to the user’s account. Check the refund procedure to know how refund of the payment is done from ${branch.storeName}.com.
    
    The invoice from ${branch.storeName} includes the product’s price (as described in the Terms and Conditions of ${branch.storeName}), ’taxes’ (as described as Taxes) and other costs mentioned as the ’Value Added charges’. Users have to pay all the charges and are responsible for reporting on their personal income or tax related issues as per the requirements of Government of India.`,
      show: "",
    },
    {
      title: `LIFETIME EXCHANGE`,
      description: `Customers can exchange the Jewels bought from us at any of our showrooms for a new jewel with ’appropriate price’ for the old jewel. ${branch.storeName} shall solely determine the ’appropriate price’ which includes the charge based on the prevailing market price of metals or stones.`,
      show: "",
    },
  ];

  return (
    <>
     <SEO
        title='Terms & Condition - Zinger Jewellers'
        description='Beginner friendly page for learning React Helmet.'
        name='Company name.'
        type='article' />
    <div
      tw="relative font-display overflow-hidden"
      className="custom_background"
    >
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Terms & Conditions" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <Heading>TERMS & CONDITIONS</Heading>
      <div className="container py-2 py-sm-5">
        <div className="accordion" id="accordionExample">
          {list.map((data, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button text-uppercase`}
                  //  ${
                  //   data.show === "" ? "collapsed" : "show"
                  // }`}
                  type="button"
                  data-bs-toggle="collapse"
                  // data-bs-target={`#one${index}`}
                >
                  {data.title}
                </button>
              </h2>
              <div
                id={`one${index}`}
                className={`accordion-collapse collapse ${
                  // data.show === "" ? "collapsed" :
                  "show"
                } ptSans`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>{data.description}</p>
                  <p>{data.description2}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};
