import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { deleteAddress, getAddress, getAllAddress } from "api/AddressApi";
import {  getCustomerId, setCustomerAddress } from "../utility/HeaderConfig";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import AddAddress from "./AddAddress";

 const getInitialState = () => {
   const initialState = {
     customerId: getCustomerId(),
     customerName: "",
     mobileNo: "",
     pincodeNumber: "",
     stateName: "",
     address2: "",
     address1: "",
     latitude: "",
     longitude: "",
     landmark: "",
     districtName: "",
     isButtonPressed: false,
   };
   return initialState;
 };

function ViewAddress(props,ref) {
 const  history =useNavigate()
  const [state, setState] = useState(getInitialState());
  const [addressList, setAddressList] = useState([]);
  const [selected, setSelected] = useState(null);

useImperativeHandle(ref,()=>({
  click:()=>{
    getAddressDetails()
  }
}))

  useEffect(() => {
    getAddressDetails();

    //eslint-disable-next-line
  }, []);
useEffect(()=>{
  handleUpdate();
  //eslint-disable-next-line
},[state])

  const getAddressDetails = () => {
    let customerId = getCustomerId();
    let filter = {
      customerEntity: customerId,
    };
    getAllAddress(filter)
      .then((data) => {
        setAddressList(data.response);
      })
      .catch((err) => console.error(err));
  };

  const getAddressById = async (id) => {
    const responseData = await getAddress(id);
    if(responseData.responseCode === 200){
      setState((prev) => ({
        ...prev,
        id: responseData.response.id,
        customerName: responseData.response.customerName,
        mobileNo: responseData.response.mobileNo,
        pincodeNumber: responseData.response.pincodeNumber,
        stateName: responseData.response.stateName,
        address2: responseData.response.address2,
        address1: responseData.response.address1,
        latitude: responseData.response.latitude,
        longitude: responseData.response.longitude,
        landmark: responseData.response.landmark,
        districtName: responseData.response.districtName,
      }));
    }
  };

  const deleteAjaxRequest = async (addressId) => {
    try {
      await deleteAddress(addressId);
      const updatedItems = addressList.filter((item) => item.id !== addressId);
      setAddressList(updatedItems);
      toast.success("Deleted Sucessfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleUpdate =(id) =>{
    if(id !== undefined){
      getAddressById(id);
    }
    if(state.id){
      history("/AddAddress",{state:{state}})
    }
  };

  const onChangedCheckbox =(i,value) => {
    setCustomerAddress(JSON.stringify(value));
    props.status(false);
    setSelected((prev) => (i === prev ? null : i));
  }

  return (
    <div className="tables">
            <table className="tabledata">
        <tr>
            <th className="tablehead">Select</th>
            <th className="tablehead">Name</th>
            <th className="tablehead">Address</th>
            <th className="tablehead">Pincode</th>
            <th className="tablehead">Action</th>
        </tr>
        {addressList.length > 0 ? addressList.map((val,key) =>
        <tr key={key}>
           <td className="tabledatas">
              <input type="radio"  
              checked={key === selected}
            onChange={() => onChangedCheckbox(key,val)}
            /></td> 
            <td className="tabledatas">{val.customerName}</td>
            <td className="tabledatas">{val.address1}{" "}{val.stateName}</td>
            <td className="tabledatas">{val.pincodeNumber}</td>
            
            <td className="tabledatas">
            <button
             className="btn btn-primary m-2"
             onClick = {()=> handleUpdate(val.id)}
           >
             Edit
           </button>
           <button
             className="btn btn-primary"
             onClick={() => deleteAjaxRequest(val.id)}
           >
             Delete
           </button>
              </td>     
         </tr>
         ) :null}
    </table>
    </div>
  );
}

export default forwardRef(ViewAddress);
