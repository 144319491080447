import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { getCustomerId, setBranchName, setCustomerMail } from "utility/HeaderConfig";
import { ECommerce } from "api/AppConfigApi";
import { getCustomer } from "api/CustomerApi";
import { getProductStatusData } from "api/ProductApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ViewAddress from "./ViewAddress";
import { getCustomerAddress } from "utility/HeaderConfig";

// eslint-disable-next-line
const Heading = tw.h1`text-center`;

export default function ShippingAddress(props) {
  const history = useNavigate();

  const [verifyAmount,setVerifyAmount] =useState(0);
  const [ propValueData, setPropVaueData]=useState(props.viewCart);
  const [checkStatus,setCheckStatus] =useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCheckProductStatus();
    getPriceCheck();
    getCustomerDetails();
    //eslint-disable-next-line
  },[]);
  
  const getCustomerDetails = () => {
    const response = getCustomer(getCustomerId());
    response.then((data) => {
      setBranchName(data.response.branchName);
      setCustomerMail(data.response.email);
    });
  };

  const getCheckProductStatus = () => {
    propValueData.forEach((element,idx) => {
      const res = getProductStatusData(element.id);
      res.then((data) =>{
      if(data.response.length > 0){
        data.response.forEach((item) =>{
          element['statusName']= item.status
          if(item.status.toLowerCase() === "sold"){          

            setCheckStatus(true)
              }
            });
          }
        })
    .catch((err) => {
          console.log(err);
        });
      if (propValueData.length -1 === idx) {
        setPropVaueData(propValueData);
      }
    });
  };

  const AddressDetails = () => {
    if(props?.total <= verifyAmount){
    if(checkStatus){  
  toast.error("Please Select Address");
  }else{
    let res = JSON.parse(getCustomerAddress());   
    props.onClick(res)
  }
 }else{
  toast.error(`The Purchase Amount Is Shouldn't Be Greater Than ${verifyAmount}`,{
    position: "top-right",
    duration: 3000,
   })
 }};

  const getPriceCheck = async () => {
    let filter = "sales_threshold";
    let appConfigResData = await ECommerce(filter);
    let appConfigthresholdValue = JSON.parse(
      appConfigResData.response.moduleField
    );
    let saleThresholdValue = 0;
    Object.entries(appConfigthresholdValue).forEach((entry) => {
      if (entry[0] === "sales_threshold_amount") {
        saleThresholdValue = entry[1];
      }
    });
    setVerifyAmount(saleThresholdValue);
  };

  return (
    <>
       <button type="button" className="btn btn-success rounded-4" onClick={()=>{history("/AddAddress")}}> 
       <i className="bi bi-plus-lg"></i> Add Address
       </button> 
       <ViewAddress status ={setCheckStatus}/>
       <div className="text-center">
         <button
           className="btn-md hoverEffect px-3 py-2 rounded"
           onClick={AddressDetails}
           type="button"
         >
           {loading ? (
             <>
               <span
                 className="spinner-border spinner-border-sm"
                 role="status"
               ></span>
               <span className="mx-2">Loading . . .</span>
             </>
           ) : (
             <>
               Save & Continue
             </>
           )}
         </button>
       </div>
    </>
  );
}
