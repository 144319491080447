import React, { useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { paymentHitory, schemeStatus } from "api/MySchemeApi.js";
import { getBranchId, getCustomerId, getInvId, setInvId } from "../utility/HeaderConfig";
import {
  convertTo12Hour,
  dateMonthYearFormatUtcToLocal,
} from "../utility/Constant";
import Divider from "../images/MyScheme/divider.png";
import { dataList } from "api/SkeletonData";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import chitImg from "../images/MyScheme/chitImg.webp";
import { useNavigate } from "react-router-dom";
import PaymentHistory from "./PaymentHistory";
import no_data from "images/no_data.png";
import { ECommerce, getCcavenueOrderStatusData, getCcavenuePaymentLinkData } from "api/AppConfigApi";
import { useVisibilityChange } from "hooks/useVisibilityChange";
import toast from "react-hot-toast";
import moment from "moment";


const Thead = tw.th`text-xs uppercase tracking-wider`;
const Tbody = tw.td`text-sm flex`;
const TabContent = tw(motion.div)`block`;

export default ({ chitStatus, Details }) => {
  const documentVisibility = useVisibilityChange();
  const history = useNavigate();

  const [showModal, setShowModal] = React.useState(false);
  const [SchemeData, setSchemeData] = useState([]);
  const [PayHistory, setPayHistory] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState({});
  const [loading, setLoading] = useState(true);
  const isSaleProcessingElement = useRef(false);
  const [payLoading, setPayLoading] = useState(false);
  const [activeLoading,setactiveLoading] =useState(null);
  const [ CCAVENUE, setCCAVENUE] = useState([]);
  const [isOnlinePaymentRestrict, setIsOnlinePaymentRestrict] = useState(false);
  const [onlinePaymentRestrictStartTime, setOnlinePaymentRestrictStartTime] = useState('');
  const [onlinePaymentRestrictDataEndTime, setOnlinePaymentRestrictDataEndTime] = useState('');
  const [onlinePaymentRestrictStartTime24, setOnlinePaymentRestrictStartTime24] = useState('');
  const [onlinePaymentRestrictDataEndTime24, setOnlinePaymentRestrictDataEndTime24] = useState('');




  useEffect(() => {
    getschemeStatus();
    //eslint-disable-next-line
  }, [chitStatus]);

  useEffect(() => {
    getPaymentAppConfigData();
    getschemeStatus();
    getPaymentRestrictAppConfigData()
    //eslint-disable-next-line
  }, []);


 const getPaymentAppConfigData = ()=>{
  let filter = "pg_endpoint_config";
  ECommerce(filter).then((data)=>{
    const moduleData = JSON.parse(data.response.moduleField);
    const endPointConfigData = moduleData;
    const ppp = endPointConfigData.paymentConfMap;

    const filtered = Object.keys(ppp)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: ppp[key]
      };
    }, {});

   setCCAVENUE({
    // MerchantId : filtered.Rasi.CCAVENUE.filter((MerchantId) => (MerchantId.key === "mid") && MerchantId.value),
    // web_returnurl : filtered.Rasi.CCAVENUE.filter((web_returnurl) => (web_returnurl.key === "web_returnurl") && web_returnurl.value)
   })
  })
   
 }

 const getPaymentRestrictAppConfigData = ()=>{
  let filter = "online_pymt_restrict_config";
  ECommerce(filter).then((data)=>{
    const moduleData = JSON.parse(data.response.moduleField);
    const startTime12Hour = convertTo12Hour(moduleData.start_time.substring(0, 5));
    const endTime12Hour = convertTo12Hour(moduleData.end_time.substring(0, 5));
    const startTime24Hour = moduleData.start_time.substring(0, 5);
    const endTime24Hour = moduleData.end_time.substring(0, 5);
    setIsOnlinePaymentRestrict(moduleData.IS_RESTRICTED);
    setOnlinePaymentRestrictStartTime(startTime12Hour);
         setOnlinePaymentRestrictDataEndTime(endTime12Hour);
         setOnlinePaymentRestrictStartTime24(startTime24Hour);
         setOnlinePaymentRestrictDataEndTime24(endTime24Hour);

    console.log("moduleData",moduleData);
  }).catch((error) =>{
    console.error(error);
  })
 };

 const isTimeInRestrictedRange = () => {
  const currentTime = moment().format("HH:mm");
  const startTime = moment(onlinePaymentRestrictStartTime24, "HH:mm");
  const endTime = moment(onlinePaymentRestrictDataEndTime24, "HH:mm");
  const now = moment(currentTime, "HH:mm");
  if (endTime.isBefore(startTime)) {
     return now.isAfter(startTime) || now.isBefore(endTime);
  } else {
     return now.isBetween(startTime, endTime);
  }
};

  const getschemeStatus = () => {
    setLoading(true);
    let id = getCustomerId();
    const response = schemeStatus({
      status: chitStatus,
      branchDetailsEntity: getBranchId(),
      customerEntity: id,
    });
    response.then((data) => {
      setSchemeData(data.response.content);
      setLoading(false);
    });
  };

  const payment = (data) => {
    setSelectedScheme(data);
    const response = paymentHitory({
      chitEnrollmentDetailsEntity: data.id,
      branchDetailsEntity: getBranchId(),
    });
    response.then((data) => {
      setPayHistory(data.response);
      setShowModal(true);
    });
  };

  const close = () => {
    setShowModal(false);
  };

  // Chit online payment with  CCAvenue //

 const OrderStatus = async(data) => { 
    setPayLoading(true)
    // const uniqueResData =  await getUniqueRequestId(data.customerId,data.chitId,Details.branchId, 'CCAVENUE', 'CHIT_APP',data.chitAmount);
    // console.log("uniqueResDataaaaaaa",uniqueResData);
    // if (uniqueResData.response.isPaymentFailure) {
    //    alert(uniqueResData.response.paymentErrorMsg.title,uniqueResData.response.paymentErrorMsg.content);
    // } else {
    // const requestData = {
    //   merchant_id : CCAVENUE.MerchantId[0].value,
    //   order_id : uniqueResData.response.uniqueRequestId,
    //   amount : parseFloat(data.chitAmount),
    //   currency : PAYMENT_FIELDS.currencyCode,
    //   // redirect_url : "https://rasiinfotech.com/pay/ccavResponseHandlerweb.php",
    //   // cancel_url : "https://rasiinfotech.com/pay/ccavResponseHandlerweb.php",
    //   language : PAYMENT_FIELDS.languageCode,
    //   billing_name : data.customerName,
    //   billing_address : Details.address1,
    //   billing_city : Details.districtName,
    //   billing_state : Details.stateName,
    //   billing_zip :Details.pincodeNumber,
    //   billing_country : Details.countryName,
    //   billing_tel : data.customerMobile,
    //   merchant_param2 : data.chitId,
    //   merchant_param3 : data.customerId,
    //   ...data,
    //   ...Details
    // }
    const randomNum = Math.floor(100000 + Math.random() * 900000).toString();
    const requestData = {
      amount: parseFloat(data.chitAmount),
      branchId: Details.branchId,
      branchName: Details.branchName,
      currencyCode: "INR",
      customerEmail: Details.email,
      customerId: Details.customerId,
      customerName: Details.name,
      customerPhone: Details.mobile,
      gatewayName: "CCAVENUE",
      orderId: randomNum,
      paymentPurpose: "ECOMM_WEB_SALES",
      productDesc: data.chitId,
      usageFrom: "WEB",
    };
    getCcavenuePaymentLinkData(requestData).then((data) =>{
      // console.log(data,'abcdef');
      Promise.all([
        setInvId(
        JSON.stringify({
          invoiceId : data.response.invoice_id,
      })
    ),
      data.response.tiny_url,
    ]).then((item) => {
        const tinyUrl = item[1];
        setPayLoading(true);
        if(tinyUrl){
          setTimeout(() => {
            setPayLoading(false);
            window.open(tinyUrl);
          }, 5000);
        } else {
          console.error("Invalid tiny URL:", tinyUrl);
          setPayLoading(false)
        }
      })
    }).catch(error => console.error(error))
        // history.push("CCAvRequestHandler", requestData)
    // }
  };

  
  useEffect(()=>{
    if(document.visibilityState === "visible" && getInvId()){
      checkOrderStatus();
    }
  },[documentVisibility])
  
  const checkOrderStatus = async () => {    
    const invId = JSON.parse(getInvId())?.invoiceId;
    // console.log('invId', invId);
    try {
        const responseData = await getCcavenueOrderStatusData(invId);
        // console.log("responseData ---->", responseData);
        if (responseData.response.order_status === "Success" || responseData.response.order_status === "Successful" || responseData.response.order_status === "Shipped") {
            isSaleProcessingElement.current = true; 
            toast.success("Payment Paid Successfully..!",{
              position: "top-right",
              duration: 1500,
            })
        }else if(responseData.response.order_status === "Unsuccessful"){
          toast.error("Payment Failure",{
            position:"top-right",
            duration: 1500
          })
        }
        if (invId) {
          localStorage.removeItem('invId');
      }
        return responseData; 
    } catch (error) {
        console.error("Error fetching order status:", error);
        throw error; 
    }
  };
  
  const controllBtn = (status) => {
    if (
      status.toLowerCase() === "waiting_for_approval" ||
      status.toLowerCase() === "cancelled"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isPaymentAvailable = isOnlinePaymentRestrict && isTimeInRestrictedRange();


  return (
    <div className="container-fluid p-0 px-sm-4 container-xl">
      <div className="row m-0 position-relative">
        {loading && (
          <div className="row ">
            {dataList.slice(0, 8).map((data, index) => (
              <div className="col-md-6 py-2" key={index}>
                <Skeleton tw="w-full" style={{ height: "200px" }} />
                <div className="card my-2 text-center py-2">
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                  <Skeleton className="px-3" tw="w-11/12" />
                </div>
              </div>
            ))}
          </div>
        )}
        {SchemeData.length === 0 && !loading ? (
          <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
        ) : (
          SchemeData.map((data,idx) => (
            <TabContent
              className="col-12 col-md-12 col-lg-6 py-3"
              variants={{
                current: {
                  opacity: 1,
                  scale: 1,
                  display: "block",
                },
                hidden: {
                  opacity: 0,
                  scale: 0.8,
                  display: "none",
                },
              }}
              transition={{ duration: 0.4 }}
              initial={data.status === chitStatus ? "current" : "hidden"}
              animate={data.status === chitStatus ? "current" : "hidden"}
            >
              <div tw="bg-white rounded-xl shadow-md overflow-hidden md:flex lg:block">
                <div tw="md:w-2/6 lg:w-full">
                  <img
                    tw="h-48 w-full object-cover  md:h-full lg:h-48"
                    src={chitImg}
                    alt="chit_img"
                  />
                </div>
                <div tw=" p-4 sm:(p-5 pt-2)  md:w-full">
                  <div tw="uppercase mb-3 tracking-wide text-2xl text-custom-100 font-semibold text-center">
                    {`${data.chitTypeName} (${data.metaChitName})`}
                    <img
                      src={Divider}
                      alt="heading-divider"
                      tw="m-auto mt-1 w-1/6"
                    />
                  </div>
                  {data.metaChitDesc ? (
                      <p
                      tw="m-0 text-sm p-2 overflow-y-scroll"
                      className="border custom_scroll "
                      style={{ height: "45px" }}
                    >
                        {data.metaChitDesc}
                        {/* <p tw="m-0 flex items-center justify-center h-full">
                          No Description
                        </p> */}
                    </p>
                  ) : (null)}
                  <table className="table table-responsive table-borderless my-3 table-sm ">
                    <tbody>
                      <tr>
                        <Thead scope="col">Chit Id</Thead>
                        <Tbody scope="col">
                          :{" "}
                          <span
                            tw="mx-2 w-full"
                            className="d-inline-block "
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={data.chitId}
                          >
                            {data.chitId}
                          </span>
                        </Tbody>
                        <Thead scope="col">Due Amt</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitAmount}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Enroll Dt</Thead>
                        <Tbody scope="col">
                          :
                          <span tw="mx-2">
                            {dateMonthYearFormatUtcToLocal(data.enrollmentDate)}
                          </span>
                        </Tbody>
                        <Thead scope="col">Last Due No</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitPaymentListSize}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Cmplt Dt</Thead>
                        <Tbody scope="col">
                          :{" "}
                          <span tw="mx-2">
                            {dateMonthYearFormatUtcToLocal(
                              data.scheduleCompletionDate
                            )}
                          </span>
                        </Tbody>
                        <Thead scope="col">Total Paid </Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.chitTotalPaymentMade}</span>
                        </Tbody>
                      </tr>
                      <tr>
                        <Thead scope="col">Duration</Thead>
                        <Tbody scope="col">
                          : <span tw="mx-2">{data.noOfMonths}</span>
                        </Tbody>
                        <Thead scope="col">Chit Type</Thead>
                        <Tbody scope="col">
                          : <span tw="sm:mx-2">{data.chitType}</span>
                        </Tbody>
                      </tr>
                      {data && data.giftIssueNumber === "Issued" ? (
                        <tr>
                        <Thead scope="col">Gift Status</Thead>
                        <Tbody scope="col">
                          : <span
                          tw="sm:mx-2"
                          className={
                            data.giftIssueNumber !== "-"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {data.giftIssueNumber !== "-"
                            ? "Issued"
                            : "Not Issued"}
                        </span>
                        </Tbody>
                      </tr>
                      ):null}
                    </tbody>
                  </table>
                  {controllBtn(data.status) && (
                    <>
                      <button
                        tw="cursor-pointer h-10 px-4 xl:px-2 text-black transition-colors duration-200 rounded mx-2  hover:(text-white bg-custom-100)"
                        onClick={() => payment(data)}
                        className="border"
                      >
                        <i className="bi bi-eye mx-1"></i> View
                      </button>
                      {data.status.toLowerCase() !== "completed" && (
                         <button
                           tw="cursor-pointer h-10 px-4 xl:px-2 text-white transition-colors duration-200 rounded mx-2 bg-custom-100 hover:(text-black bg-white)"
                           className="border"
                           disabled={payLoading}
                           onClick={() => {
                             if (data.chitType.toLowerCase() === "digigold" || data.chitType.toLowerCase() === "digisilver") {
                               history("/TechWallet", {state: data});
                             } else {
                               OrderStatus(data);
                             }
                             setactiveLoading(idx);
                           }}
                         >
                           {!isPaymentAvailable ? (
                             payLoading && (idx === activeLoading) ? (
                               <>
                                 <span className="spinner-border spinner-border-sm" role="status"></span>
                                 <span className="mx-2">Loading . . .</span>
                               </>
                             ) : (
                               <>
                                 <i className="bi bi-credit-card mx-1"></i> Pay Now 
                               </>
                             )
                           ) : (
                             <span>{`Online Payment is Restricted between ${onlinePaymentRestrictStartTime} to ${onlinePaymentRestrictDataEndTime}`}</span>
                           )}
                         </button>
                       )}
                       
                    </>
                  )}
                </div>
              </div>
            </TabContent>
          ))
        )}
      </div>
      {/* ---- Modal --- */}
      {showModal ? (
        <PaymentHistory
          payDetails={PayHistory}
          close={close}
          currentChit={selectedScheme}
        />
      ) : (
        ""
      )}
    </div>
  );
};
