import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { myWishList } from "../api/WishListApi.js";
import { Link, useNavigate } from "react-router-dom";
import ProductCommon from "../components/ProductLike&Wish";
import { getCustomerId } from "../utility/HeaderConfig";
import no_data from "images/no_data.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonLoader from "components/SkeletonLoader";

const Container = tw.div`relative font-display overflow-hidden`;
const TabContent = tw(motion.div)`block `;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto  sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`  text-center text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardContent = tw.p`my-2 text-sm font-medium text-custom-100  uppercase`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;

export default ({ configImages }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getCustWish();
    getBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "whishList") {
          setBanner(data.value);
        }
      });
  };

  const history = useNavigate();

  const getCustWish = () => {
    myWishList(getCustomerId()).then((data) => {
      setCategoryList(data.response);
      setLoading(false);
    });
  };

  return (
    <Container>
      {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Whish List" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )}
      <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          Whishlist
        </span>
      </div>
      <div className=" py-5 mb-5 position-relative">
        <div className="container container-influid-md">
          <div className="row">
            {categoryList.length === 0 && !loading ? (
              <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
            ) : (
              categoryList.map(
                (categoryItem, index) =>
                  categoryItem.statusName === "Sale" && (
                    <TabContent
                      className="col-md-6 col-lg-4 col-xl-3 d-block"
                      ket={index}
                    >
                      <CardContainer>
                        <ProductCommon
                          categoryItem={categoryItem}
                          common="common"
                        />
                        <Card
                          className="group"
                          initial="rest"
                          whileHover="hover"
                          animate="rest"
                          onClick={() =>
                            history(`/ProductDetails/${categoryItem.id}`, {state:{categoryItem}})
                          }
                        >
                          <div className="overflow-hidden">
                            <CardImageContainer
                              imageSrc={
                                categoryItem.productImageList == null
                                  ? Noimg
                                  : categoryItem.productImageList[0].imageUrl
                              }
                              tw="transform transition duration-500  group-hover:(scale-125 rotate-12)"
                            ></CardImageContainer>
                            <div
                              className="badge"
                              tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                            >
                              {categoryItem.statusName === "Sale"
                                ? "Available"
                                : "Sold"}
                            </div>
                          </div>
                          <CardText>
                            <CardContent
                              className="text-uppercase"
                              tw="truncate w-full"
                            >
                              <CardContent
                                className="capitalize"
                                tw="truncate w-full text-custom-100 uppercase"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={categoryItem.productName}
                              >
                                {categoryItem.productName}
                              </CardContent>
                            </CardContent>
                          </CardText>
                        </Card>
                      </CardContainer>
                    </TabContent>
                  )
              )
            )}
            {loading && <SkeletonLoader />}
            <DecoratorBlob1 />
            <DecoratorBlob2 />
          </div>
        </div>
      </div>
    </Container>
  );
};
