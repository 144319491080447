// const BASE_URL = "https://vclive.prod-rasiinfotech.in";
// const BASE_URL = "https://zinger.prod-rasiinfotech.in";
// const BASE_URL = "https://rj.prod-rasiinfotech.in";
// const WEB_URL  = "https://vclive.prod-rasiinfotech.in";

const WEB_URL  = "https://trysumangaleejewellers.in";

const BASE_URL = "https://froads.trysumangaleejewellers.in";


export { BASE_URL , WEB_URL};
