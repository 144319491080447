import React, { memo, useEffect, useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Noimg from "../images/no_img.jpg";
import no_data from "images/no_data.png";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { currentRegister } from "utility/Constant";
import { getBranchId, getOrderStatus } from "utility/HeaderConfig";
import { getProduct } from "api/ProductApi";
import Divider from "../images/MyScheme/divider.png";
import { dataList } from "api/SkeletonData";
import ProductCommon from "../components/ProductLike&Wish";

const TabContent = tw(motion.div)`block block w-full cursor-pointer`;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto   max-w-xs mx-auto sm:max-w-none sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;

function RelatedProduct({ Details }) {
  const [categoryList, setCategoryList] = useState([]);

  const history = useNavigate();
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 900, itemsToShow: 4 },
  ];

  useEffect(() => {
    if (Details.length !== 0) {
      getProductList();
    }
    //eslint-disable-next-line
  }, [Details]);

  const getProductList = () => {
    let filterData = {
      removeNTP: "NTP-",
      removeHP: "HP-",
      beforeCreatedAt: currentRegister(),
      branch: getBranchId(),
      metaProdTypeEnty: Details.productTypeId ? Details.productTypeId : "",
      metaProdCateEnty: Details.productCateId ? Details.productCateId : "",
      statusId: getOrderStatus(),
      allowCatalogue: 1,
      productDemoStatus: "Available_For_Demo,Demo_Completed,Demo_Cancelled",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    if (filterData.metaProdCateEnty === "") {
      delete filterData.metaProdCateEnty;
    }
    getProduct(50, filterData)
      .then((data) => {
        setCategoryList(data.response.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          className="bi bi-arrow-left-circle"
          tw="sm:text-4xl text-3xl text-custom-100 hover:text-custom-400"
        ></i>
      ) : (
        <i
          className="bi bi-arrow-right-circle"
          tw="sm:text-4xl text-3xl text-custom-100 hover:text-custom-400"
        ></i>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  return (
    <section tw="bg-red-100 py-10" className="custom_background">
      <div tw="pb-5 uppercase tracking-wide text-3xl text-black font-semibold text-center">
        Similar Products
        <img src={Divider} alt="heading-divider" tw="m-auto mt-2 w-1/6" />
      </div>
      <div className="container">
        <Carousel
          itemPadding={[0, 10]}
          itemsToShow={5}
          outerSpacing={0}
          showEmptySlots
          breakPoints={breakPoints}
          renderArrow={myArrow}
        >
          {categoryList && categoryList.length > 0
            ? categoryList.map((categoryItem, index) => (
                <TabContent
                  key={index}
                  transition={{ duration: 0.4 }}
                  className="col-md-6 col-lg-4 col-xl-3"
                  style={{ paddingTop: "20px" }}
                >
                  <CardContainer>
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                    >
                      <ProductCommon categoryItem={categoryItem} />
                      <CardImageContainer
                        imageSrc={
                          categoryItem.images === "@#@"
                            ? Noimg
                            : categoryItem.images.split("@#@")[0]
                        }
                        onClick={() =>
                          history(`/ProductDetails/${categoryItem.id}`, {state:{categoryItem}})
                        }
                      >
                        <div
                          className="badge"
                          tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                        >
                          {categoryItem.stockStatus === "Sale" && "Available"}
                        </div>
                      </CardImageContainer>
                      <CardText>
                        <div className="d-flex justify-content-between mb-1 align-items-center">
                          <div className="col-md-7">
                            <CardTitle className="capitalize">
                              {categoryItem.metalType}
                            </CardTitle>
                            <CardContent
                              className="capitalize"
                              tw="truncate w-full "
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={categoryItem.title}
                            >
                              {categoryItem.title}
                            </CardContent>
                          </div>
                          <div className="col-md-5 text-end">
                            <b tw="text-lg flex justify-end">
                              <span className="mx-1">&#8377;</span>{" "}
                              {Math.trunc(categoryItem.total)}
                            </b>
                            {categoryItem.goldPurity !== "-" && (
                              <small tw="text-xs">
                                {categoryItem.goldPurity}
                              </small>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </Card>
                  </CardContainer>
                </TabContent>
              ))
              :
            // : dataList.slice(0, 4).map((data , index) => (
            //     <div className="w-100 py-2" key={index}>
            //       <Skeleton tw="w-full" style={{ height: "260px" }} />
            //       <div className="card my-2 text-center py-2">
            //         <Skeleton className="px-3" tw="w-11/12" />
            //         <Skeleton className="px-3" tw="w-11/12" />
            //         <Skeleton className="px-3" tw="w-11/12" />
            //         <Skeleton className="px-3" tw="w-11/12" />
            //       </div>
            //     </div>
              // ))
            <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
              }
        </Carousel>
      </div>
    </section>
  );
}

export default memo(RelatedProduct);
