import tw from "twin.macro";
import React, { memo } from 'react';

import userImg from "../images/MyScheme/user.png";

const Relative = tw.div`relative`;
const AboutContainer = tw.div`py-4`;
const AboutMe = tw.div`py-3 flex items-stretch`;
const AboutDetails = tw.div`pl-5`;

const Heading = tw.h1`text-center font-dancing block text-black  text-3xl mb-10`;
const ProfileHeading = tw(Heading)`w-full p-6 text-4xl mb-0`;

function MyProfile({Details}) {
  return (
    <div className=" d-none d-xl-block col-xl-3  p-0">
    <div className="container-fluid border-bottom mx-4">
      <ProfileHeading> My Profile </ProfileHeading>
    </div>
    <div className="container-fluid">
      <Relative tw="text-center pt-5 pb-2">
        <img
          src={userImg}
          alt="user"
          tw="w-24 m-auto rounded-full border-8 border-custom-200"
        />
        <span
          className="badge position shadow-lg px-2 py-1 "
          tw="bg-custom-100 relative left-0"
        >
          New
        </span>
      </Relative>
      <AboutContainer>
        <AboutMe>
          <i
            className="bi bi-person p-2 px-3"
            tw="self-start bg-custom-200 text-black rounded text-base"
          ></i>
          <AboutDetails>
            <h6 tw="mb-1">Your Name</h6>
            <span tw="text-gray-800">{Details.name}</span>
          </AboutDetails>
        </AboutMe>
        <AboutMe>
          <i
            className="bi bi-building p-2 px-3"
            tw="self-start bg-custom-200 text-black rounded text-base"
          ></i>
          <AboutDetails>
            <h6 tw="mb-1">Your Branch Name</h6>
            <span tw="text-gray-800">{Details.branchName}</span>
          </AboutDetails>
        </AboutMe>
        <AboutMe>
          <i
            className="bi bi-phone p-2 px-3"
            tw="self-start bg-custom-200 text-black rounded text-base"
          ></i>
          <AboutDetails>
            <h6 tw="mb-1">Your Mobile Number</h6>
            <span tw="text-gray-800">{Details.mobile}</span>
          </AboutDetails>
        </AboutMe>
        <AboutMe>
          <i
            className="bi bi-envelope-check p-2 px-3"
            tw="self-start bg-custom-200 text-black rounded text-base"
          ></i>
          <AboutDetails>
            <h6 tw="mb-1">Your Email Id</h6>
            <span tw="text-gray-800">{Details.email}</span>
          </AboutDetails>
        </AboutMe>
        <AboutMe>
          <i
            className="bi bi-geo-alt p-2 px-3"
            tw="self-start bg-custom-200 text-black rounded text-base"
          ></i>
          <AboutDetails>
            <h6 tw="mb-1">Your Address</h6>
            <span tw="text-gray-800">{Details.address1}</span>
          </AboutDetails>
        </AboutMe>
      </AboutContainer>
    </div>
  </div>
  )
}
export default memo(MyProfile);
