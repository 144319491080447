import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCartContext } from "context/UseCartContext";
import { getCustomerId } from 'utility/HeaderConfig';

function BookAppointment({ category }) {
  const history = useNavigate();
  const { dispatch } = useCartContext();

  const handleClick = () => {

    if (getCustomerId() === undefined) {
      dispatch({ type: "MODAL", payload: "login" });
    } else {
      history("/BookAnAppointment", { state: { category } })
    }

  };

  return (
    <div className='col-12 col-lg-4'>
      <div id="holder">
        <div className="button" onClick={handleClick}>
          <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">Book An Enquiry</p>
          <div className="btnTwo">
            <p className="btnText2"><i className="bi bi-calendar2-week mx-2 text-white" tw="text-xl "></i> </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(BookAppointment);
