import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Chit from "../components/Chit.js";
import { getBranchId, getCustomerId } from "../utility/HeaderConfig";
import { Link } from "react-router-dom";
import { getCustomer } from "api/CustomerApi";
import MyProfile from "./MyProfile.js";
import { useLocation } from "react-router-dom";
import { dayClose } from "api/NewSchemeApi.js";
import moment from "moment";
import { filterFormatToDate } from "utility/Constant.js";
import { getAllSale } from "api/TrackingApi.js";

const value = [
  {
    icon1: "bi bi-check-circle",
    icon: "bi bi-check-circle icon-bg",
    name: "Active",
    count: "2",
    click: "Active",
  },
  {
    icon1:"bi bi-hourglass-top",
    icon: "bi bi-hourglass-top icon-bg",
    name: "Pending",
    count: "3",
    click: "Waiting_For_Approval",
  },
  {
    icon1:"bi bi-door-closed ",
    icon: "bi bi-door-closed icon-bg",
    name: "Close",
    count: "1",
    click: "Completed",
  },
  {
    icon1: "bi bi-x-circle icon-bg",
    icon: "bi bi-x-circle icon-bg",
    name: "Cancelled",
    count: "6",
    click: "Cancelled",
  },
];

const TabControl = styled.div`
  ${tw`bg-custom-green rounded p-6  cursor-pointer`}
  &:hover {
    ${tw`bg-custom-100 text-white`}
  }
  ${(props) => props.active && tw`bg-custom-100! text-gray-100!`}
  }
`;

// const Heading = tw.h1`text-center`;

export default () => {
  const [Details, setDetails] = useState({});
  const [Status, setStatus] = useState("Active");
  const [orderLists, setorderLists] = useState([]);
  const [trackingStatus, setTrackingStatus] = useState(false);
  const [dayCloseDate, setDayClose] = useState("");

  const location = useLocation();
  const myparam = location.state;

  useEffect(() => {
    getCustomerDetails();
    dayCloseDay();
  }, []);

  useEffect(() => {
    if (Details.customerId && dayCloseDate) {
      orderList();
    }
  }, [Details.customerId, dayCloseDate]);

  const getCustomerDetails = () => {
    const response = getCustomer(getCustomerId());
    response.then((data) => {
      setDetails({
        name: data.response.name,
        mobile: data.response.mobile,
        email: data.response.email,
        address1:
          data.response.customerAddress != null
            ? data.response.customerAddress.address1
            : "",
        branchName: data.response.branchName != null ? data.response.branchName : "-",
        branchId : data.response.branchId,
        customerId : data.response.id,
        countryName : data.response.countryName !== undefined ? data.response.countryName : null,
        stateName : data.response.stateName !== undefined ? data.response.stateName : null,
        pincodeNumber : data.response.pincodeNumber !== undefined ? data.response.pincodeNumber : null,
        districtName : data.response.districtName !== undefined ? data.response.districtName : null
      });
    });
  };

  const schemeStatus = (currentStatus) => {
    setStatus(currentStatus);
  };

  // tracking Order
  const dayCloseDay = () => {
    dayClose({
      module: "Sales",
      branchId: getBranchId(),
    }).then((data) => {
      if (data.response.length > 0) {
        let dateString = data.response[0].activeDate;
        let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
        let dayCloseDate = date.format("YYYY-MM-DD");
        setDayClose(dayCloseDate);
      }
    });
  };

  const orderList = () => {
    let filters = {
      customerEntity: Details.customerId,
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      branchDetailsEntity: Details.branchId,
    };
    getAllSale(filters).then((data) => {
      const filters = data.response.filter((data) => {
        return data.source === "ECOM_ONLINE";
      });
      // let sort = Ascending_Order_Format(filters, "createdAt");
      // setorderLists(sort);
      setTrackingStatus(false);
    });
  };

  const trackingStatusFunc = (status) => {
    console.log(status);
    setTrackingStatus(status);
  };

  return (
    <div className="row m-0 position-relative">
      <MyProfile Details={Details} />
      <div className="col-lg-12 col-xl-9 py-4 px-0" tw="bg-gray-100">
        <div className="container-fluid ">
          <h4 tw="font-dancing">Dashboard</h4>
          <div className="ptSans" tw="text-sm">
            <Link to="/MainLandingPage" tw="ml-3 no-underline text-black">
              Home
            </Link>
            / <span tw="ml-3">MyScheme</span> /
            <span tw="ml-3 text-custom-100">Dashboard</span>
          </div>

          <div className="row my-3">
            {value.length > 0 ? value.map((data, idx) => (
              <div className="col-lg-3 col-md-6 py-2">
                <div
                  className="card o-hidden border-0 overflow-hidden"
                  onClick={() => setStatus(data.click)}
                >
                  <TabControl
                    className="card-body"
                    key={idx}
                    active={Status === data.click}
                  >
                    <div className="media static-top-widget d-flex justify-content-around text-center">
                      <div className="align-self-center text-center">
                        {/* {data.icon1} */}
                        <i className={data.icon1} tw="text-4xl text-white"></i>
                      </div>
                      <div className="media-body pl-3 text-white">
                        <h4
                          className="mb-1 ptSans text-uppercase"
                          tw="text-xl font-semibold tracking-widest"
                        >
                          {data.name}
                        </h4>
                        {/* <span tw="text-sm">{data.count}</span> */}
                        {/* {data.icon} */}
                        <i className={data.icon}></i>
                      </div>
                    </div>
                  </TabControl>
                </div>
              </div>
            )) : ''}
          </div>
        </div>
        <Chit chitStatus={Status} Details={Details} />
      </div>
    </div>
  );
};
